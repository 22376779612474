import './App.css';


import {Route, Routes} from "react-router-dom";
import Main from "./screens/MainScreen";



function App() {
  

    return (
        <div className="App">
            <Routes>
                <Route index element={<Main/>}/>
            </Routes>
        </div>
    );
}

export default App;
