import React from 'react';
import './TelegramTextArea.css';

const   TelegramTextArea = (props) => {
    return (
        <textarea {...props} className={'telegramTextArea'}/>
    );
};

export default TelegramTextArea;
