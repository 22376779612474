import React, { useState }from 'react';
import TelegramHeader from "../components/kit/Header/TelegramHeader";
import TelegramText from "../components/kit/Text/TelegramText";
import TelegramSelect from "../components/kit/Select/TelegramSelect";
import TelegramButton from "../components/kit/Button/TelegramButton";
// import {useNavigate} from "react-router-dom";
// import { PATH_SERVER} from "../../constants/Paths";
import TelegramScreen from "../components/kit/Screen/TelegramScreen";
import TelegramTextArea from '../components/kit/TextArea/TelegramTextArea';
// import TelegramDetailedButton from "../../components/kit/DetailedButton/TelegramDetailedButton";
import {useEffect} from "react";
import {useTelegram} from "../hooks/useTelegram";
import {validateData} from "../logic/server/HttpClient";

const Main = () => {
    // const {user} = useTelegram()
    // const navigate = useNavigate();
    

    const options = [
        { id: 1, value: '😀Happy' },
        { id: 2, value: '😞Sad' },
        { id: 3, value: '😡Angry' },
        { id: 4, value: '😃Excited' },
        { id: 5, value: '😴Tired' },
        { id: 6, value: '😐Bored' },
        { id: 7, value: '😰Anxious' },
        { id: 8, value: '😖Stressed' },
        { id: 9, value: '😌Relaxed' },
        { id: 10, value: '😕Confused' }
      ]
    
    const [selectedMood, setSelectedMood] = useState(options[0].id);
    const [currentThinking, setCurrentThinking] = useState('');
    const [currentDoing, setCurrentDoing] = useState('');

    const {webApp} = useTelegram();
    
  



    const onMainClickAppButton = () => {

    // TODO: Add request to backend here 
        console.log('test');
        validateData(webApp.initData, webApp.initDataUnsafe.hash)
        .then(response => {
            // handle response
            console.log('success');
        })
        .catch(error => {
            // handle error
            console.log("error");
        });

    };



    useEffect(() => {
        webApp.ready();
        webApp.expand();
        webApp.MainButton.show();
        webApp.MainButton.text="Submit";

        const onMainClick = () => {
            webApp.showAlert(`Main button click. Selected option: ${selectedMood}\n
            Current thinking: ${currentThinking}\n
            Current doing: ${currentDoing}`);

            validateData(webApp.initData, webApp.initDataUnsafe.hash)
            .then(response => {
                // handle response
                webApp.showAlert('success');
                console.log('success');
            })
            .catch(error => {
                // handle error
                webApp.showAlert('error');
                console.log("error");
            });

        };


        // webApp.BackButton.onClick(onBackClick)
        webApp.MainButton.onClick(onMainClick);



        return () => {
            // webApp.BackButton.offClick(onBackClick)
            webApp.MainButton.offClick(onMainClick)
        };
    }, [webApp, selectedMood, currentThinking, currentDoing])
    


    return (
        <TelegramScreen showbackbutton={false} >
            <TelegramHeader>
                <TelegramText className={'telegramTitle'}>Mood Journal</TelegramText>
            </TelegramHeader>

            <TelegramText className={'telegramText'}>How are you feeling today?</TelegramText>

            <TelegramSelect
                defaultValue={options[0]}
                multiple={false}
                elements={options}
                onChange={e => setSelectedMood(e.target.selectedIndex)}
            />
            
            <TelegramText className={'telegramText'}>What are you doing?</TelegramText>
            <TelegramTextArea className={'telegramTextArea'} onChange={e => setCurrentDoing(e.target.value)} rows={4}/>
            <TelegramText className={'telegramText'}>What are you thinking?</TelegramText>
            <TelegramTextArea className={'telegramTextArea'} onChange={e => setCurrentThinking(e.target.value)} rows={4}/>
            <TelegramButton onClick={onMainClickAppButton}>Submit</TelegramButton>
        </TelegramScreen>
    );
};

export default Main;
