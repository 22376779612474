

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */

const API_URL = "localhost:3000";

export const sendMessageToServer = async (message, queryId) => {
    const body = {
        queryId: queryId,
        message: message
    }

    const messagesEndpoint = `${API_URL}/messages`
    return await fetch(messagesEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}

export const validateData = async (webInit, hash) => {
    const body = {
        webInit: String,
        hash: String
    }

    const endpoint = `${API_URL}/validate`
    return await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
}